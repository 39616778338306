@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  font-size : 100%;
  @apply scroll-smooth;
}

*, *::after{
  @apply transition-all duration-200 appearance-none outline-none;
}
body{
  @apply bg-primary bg-opacity-10 overflow-x-hidden;
}